export default [
  'ABHYUDAYA COOPERATIVE BANK LIMITED',
  'AHMEDABAD MERCANTILE COOPERATIVE BANK',
  'AHMEDNAGAR MERCHANTS CO-OP BANK LTD',
  'AIRTEL PAYMENTS BANK LIMITED',
  'AKOLA JANATA COMMERCIAL COOPERATIVE BANK',
  'ALMORA URBAN COOPERATIVE BANK LIMITED',
  'AMBARNATH JAIHIND COOP BANK LTD AMBARNATH',
  'ANDHRA PRADESH GRAMEENA VIKAS BANK',
  'ANDHRA PRAGATHI GRAMEENA BANK',
  'APNA SAHAKARI BANK LIMITED',
  'ARVIND SAHAKARI BANK LTD',
  'AU SMALL FINANCE BANK LIMITED',
  'AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED',
  'AXIS BANK',
  'B N P PARIBAS',
  'BANDHAN BANK LIMITED',
  'BANK OF AMERICA',
  'BANK OF BAHARAIN AND KUWAIT BSC',
  'BANK OF BARODA',
  'BANK OF CEYLON',
  'BANK OF INDIA',
  'BANK OF MAHARASHTRA',
  'BARCLAYS BANK',
  'BASSEIN CATHOLIC COOPERATIVE BANK LIMITED',
  'BHAGINI NIVEDITA SAHAKARI BANK LTD PUNE',
  'BHARAT COOPERATIVE BANK MUMBAI LIMITED',
  'BOMBAY MERCANTILE COOPERATIVE BANK LTD',
  'CANARA BANK',
  'CAPITAL SMALL FINANCE BANK LIMITED',
  'CENTRAL BANK OF INDIA',
  'CHHATTISGARH RAJYA GRAMIN BANK',
  'CITI BANK',
  'CITIZEN CREDIT COOPERATIVE BANK LIMITED',
  'CITY UNION BANK LIMITED',
  'COASTAL LOCAL AREA BANK LTD',
  'CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK CALYON BANK',
  'CREDIT SUISEE AG',
  'CSB BANK LIMITED',
  'CTBC BANK CO LTD',
  'Darussalam Co operative Urban Bank Ltd',
  'DBS BANK INDIA LIMITED',
  'DCB BANK LIMITED',
  'DEOGIRI NAGARI SAHAKARI BANK LTD. AURANGABAD',
  'DEPOSIT INSURANCE AND CREDIT GUARANTEE CORPORATION',
  'DEUSTCHE BANK',
  'DHANALAKSHMI BANK',
  'DMK JAOLI BANK',
  'DOHA BANK',
  'DOMBIVLI NAGARI SAHAKARI BANK LIMITED',
  'DURGAPUR STEEL PEOPLES CO-OPERATIVE BANK LTD',
  'EMIRATES NBD BANK P J S C',
  'EQUITAS SMALL FINANCE BANK LIMITED',
  'ESAF SMALL FINANCE BANK LIMITED',
  'EXPORT IMPORT BANK OF INDIA',
  'FEDERAL BANK',
  'FINCARE SMALL FINANCE BANK LTD',
  'FINO PAYMENTS BANK',
  'FIRST ABU DHABI BANK PJSC',
  'FIRSTRAND BANK LIMITED',
  'G P PARSIK BANK',
  'GS Mahanagar Co-operative Bank Limited, Mumbai',
  'HARYANA STATE COOPERATIVE BANK',
  'HDFC BANK',
  'HIMACHAL PRADESH STATE COOPERATIVE BANK LTD',
  'HSBC BANK',
  'Hutatma Sahakari Bank Ltd',
  'ICICI BANK LIMITED',
  'IDBI BANK',
  'IDFC FIRST BANK LTD',
  'IDUKKI DISTRICT CO OPERATIVE BANK LTD',
  'INDIA POST PAYMENT BANK',
  'INDIAN BANK',
  'INDIAN OVERSEAS BANK',
  'INDUSIND BANK',
  'INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED',
  'INDUSTRIAL BANK OF KOREA',
  'Irinjalakuda Town Co-Operative Bank Ltd',
  'JALGAON JANATA SAHAKARI BANK LIMITED',
  'JAMMU AND KASHMIR BANK LIMITED',
  'JANA SMALL FINANCE BANK LTD',
  'JANAKALYAN SAHAKARI BANK LIMITED',
  'JANASEVA SAHAKARI BANK BORIVLI LIMITED',
  'JANASEVA SAHAKARI BANK LIMITED',
  'JANATA SAHAKARI BANK LIMITED',
  'JANATHA SEVA COOPERATIVE BANK LTD',
  'JIO PAYMENTS BANK LIMITED',
  'JP MORGAN BANK',
  'KALLAPPANNA AWADE ICHALKARANJI JANATA SAHAKARI BANK LIMITED',
  'KALUPUR COMMERCIAL COOPERATIVE BANK',
  'KARNATAKA BANK LIMITED',
  'KARNATAKA GRAMIN BANK',
  'KARNATAKA VIKAS GRAMEENA BANK',
  'KARUR VYSYA BANK',
  'KEB Hana Bank',
  'KERALA GRAMIN BANK',
  'KOOKMIN BANK',
  'KOTAK MAHINDRA BANK LIMITED',
  'KOZHIKODE DISTRICT COOPERATIAVE BANK LTD',
  'Maharashtra Gramin Bank',
  'MAHARASHTRA STATE COOPERATIVE BANK',
  'Mahesh Sahakari Bank Ltd Pune',
  'MASHREQBANK PSC',
  'MIZUHO BANK LTD',
  'MODEL COOPERATIVE BANK LTD',
  'MUFG BANK, LTD',
  'NAGPUR NAGARIK SAHAKARI BANK LIMITED',
  'NATIONAL BANK FOR AGRICULTURE AND RURAL DEVELOPMENT',
  'NAV JEEVAN CO OP BANK LTD',
  'NEW INDIA COOPERATIVE BANK LIMITED',
  'NKGSB COOPERATIVE BANK LIMITED',
  'NORTH EAST SMALL FINANCE BANK LIMITED',
  'NSDL Payments Bank Limited',
  'NUTAN NAGARIK SAHAKARI BANK LIMITED',
  'Pavana Sahakari Bank LTD',
  'PAYTM PAYMENTS BANK LTD',
  'PRIME COOPERATIVE BANK LIMITED',
  'PT BANK MAYBANK INDONESIA TBK',
  'PUNJAB AND SIND BANK',
  'PUNJAB NATIONAL BANK',
  'QATAR NATIONAL BANK SAQ',
  'RABOBANK INTERNATIONAL',
  'RAJARAMBAPU SAHAKARI BANK LIMITED',
  'RAJARSHI SHAHU SAHAKARI BANK LTD  PUNE',
  'RAJASTHAN MARUDHARA GRAMIN BANK',
  'RAJGURUNAGAR SAHAKARI BANK LIMITED',
  'RAJKOT NAGRIK SAHAKARI BANK LIMITED',
  'Rajnandgaon District Central Co-operative Bank Ltd',
  'RBL BANK LIMITED',
  'RESERVE BANK OF INDIA',
  'SAMARTH SAHAKARI BANK LTD',
  'SANT SOPANKAKA SAHAKARI BANK LTD',
  'Saraspur Nagrik Co operative Bank Ltd Saraspur',
  'SARASWAT COOPERATIVE BANK LIMITED',
  'Satara Sahakari Bank Ltd',
  'SBER BANK',
  'SBM BANK INDIA LIMITED',
  'SHIKSHAK SAHAKARI BANK LIMITED',
  'SHINHAN BANK',
  'Shivalik Small Finance Bank Limited',
  'Shree Kadi Nagarik Sahakari Bank Limited',
  'SHRI CHHATRAPATI RAJASHRI SHAHU URBAN COOPERATIVE BANK LIMITED',
  'Shri Veershaiv Co Op Bank Ltd',
  'SIR M VISVESVARAYA CO OPERATIVE BANK LTD',
  'Smriti Nagrik Sahakari Bank Maryadit',
  'SOCIETE GENERALE',
  'SOLAPUR JANATA SAHAKARI BANK LIMITED',
  'SOUTH INDIAN BANK',
  'SREE CHARAN SOUHARDHA CO OPERATIVE BANK LTD',
  'STANDARD CHARTERED BANK',
  'STATE BANK OF INDIA',
  'Suco Souharda Sahakari Bank Ltd',
  'SUMITOMO MITSUI BANKING CORPORATION',
  'SURAT NATIONAL COOPERATIVE BANK LIMITED',
  'SURYODAY SMALL FINANCE BANK LIMITED',
  'SUTEX COOPERATIVE BANK LIMITED',
  'TAMILNAD MERCANTILE BANK LIMITED',
  'TELANGANA STATE COOP APEX BANK',
  'TEXTILE TRADERS CO OPERATIVE BANK LTD',
  'THE A.P. MAHESH COOPERATIVE URBAN BANK LIMITED',
  'THE AHMEDNAGAR DISTRICT CENTRAL CO-OPERATIVE BANK LTD',
  'The Ajara Urban Co op Bank Ltd Ajara',
  'THE AKOLA DISTRICT CENTRAL COOPERATIVE BANK',
  'The Akola Urban Cooperative Bank Limited',
  'THE ANDHRA PRADESH STATE COOPERATIVE BANK LIMITED',
  'The Banaskantha Mercantile Cooperative Bank Ltd',
  'THE BANK OF NOVA SCOTIA',
  'THE BARAMATI SAHAKARI BANK LTD',
  'THE COSMOS CO OPERATIVE BANK LIMITED',
  'THE DELHI STATE COOPERATIVE BANK LIMITED',
  'THE GADCHIROLI DISTRICT CENTRAL COOPERATIVE BANK LIMITED',
  'THE GREATER BOMBAY COOPERATIVE BANK LIMITED',
  'THE GUJARAT STATE COOPERATIVE BANK LIMITED',
  'THE HASTI COOP BANK LTD',
  'THE JALGAON PEOPELS COOPERATIVE BANK LIMITED',
  'THE KALYAN JANATA SAHAKARI BANK LTD.',
  'THE KANGRA CENTRAL COOPERATIVE BANK LIMITED',
  'THE KANGRA COOPERATIVE BANK LIMITED',
  'THE KARAD URBAN COOPERATIVE BANK LIMITED',
  'THE KARANATAKA STATE COOPERATIVE APEX BANK LIMITED',
  'The Karnataka State Cooperative Apex Bank Ltd',
  'The Kerala State Co Operative Bank Ltd',
  'The Kolhapur Urban Co-op Bank Ltd',
  'THE KURMANCHAL NAGAR SAHAKARI BANK LIMITED',
  'The Malad Sahakari Bank Ltd',
  'The Malkapur Urban Co Operative Bank Ltd Malkapur',
  'The Meghalaya Co-operative Apex Bank Ltd',
  'THE MEHSANA URBAN COOPERATIVE BANK',
  'THE MUMBAI DISTRICT CENTRAL COOPERATIVE BANK LIMITED',
  'THE MUNICIPAL COOPERATIVE BANK LIMITED',
  'The Muslim Co-operative Bank Ltd',
  'THE NAINITAL BANK LIMITED',
  'THE NASIK MERCHANTS COOPERATIVE BANK LIMITED',
  'THE NAVNIRMAN CO-OPERATIVE BANK LIMITED',
  'THE NAWANAGAR COOPERATIVE BANK LTD',
  'THE NILAMBUR CO OPERATIVE URBAN BANK LTD NILAMBUR',
  'THE ODISHA STATE COOPERATIVE BANK LTD',
  'THE PANDHARPUR URBAN CO OP. BANK LTD. PANDHARPUR',
  'THE PUNJAB STATE COOPERATIVE BANK LTD',
  'The Pusad Urban Cooperative Bank Ltd Pusad',
  'THE RAJASTHAN STATE COOPERATIVE BANK LIMITED',
  'THE SATARA DISTRICT CENTRAL COOPERATIVE BANK LTD',
  'THE SHAMRAO VITHAL COOPERATIVE BANK',
  'THE SINDHUDURG DISTRICT CENTRAL COOP BANK LTD',
  'THE SURAT DISTRICT COOPERATIVE BANK LIMITED',
  'THE SURATH PEOPLES COOPERATIVE BANK LIMITED',
  'THE TAMIL NADU STATE APEX COOPERATIVE BANK',
  'THE THANE BHARAT SAHAKARI BANK LIMITED',
  'THE THANE DISTRICT CENTRAL COOPERATIVE BANK LIMITED',
  'THE UDAIPUR URBAN CO OPERATIVE BANK LTD',
  'THE URBAN CO OPERATIVE BANK Ltd No ONE SEVEN FIVE EIGHT PERINTHALMANNA',
  'THE VARACHHA COOPERATIVE BANK LIMITED',
  'The Vijay Co Operative Bank Limited',
  'THE VISHWESHWAR SAHAKARI BANK LIMITED',
  'THE WEST BENGAL STATE COOPERATIVE BANK',
  'THE ZOROASTRIAN COOPERATIVE BANK LIMITED',
  'THRISSUR DISTRICT CO-OPERATIVE BANK LTD',
  'TJSB SAHAKARI BANK LTD',
  'TUMKUR GRAIN MERCHANTS COOPERATIVE BANK LIMITED',
  'UCO BANK',
  'Ujjivan Small Finance Bank Limited',
  'UNION BANK OF INDIA',
  'UNITED OVERSEAS BANK LIMITED',
  'UTKARSH SMALL FINANCE BANK',
  'UTTAR PRADESH COOPERATIVE BANK LTD',
  'VASAI JANATA SAHAKARI BANK LTD',
  'VASAI VIKAS SAHAKARI BANK LIMITED',
  'WOORI BANK',
  'YES BANK',
  'ZILA SAHAKRI BANK LIMITED GHAZIABAD'
]
