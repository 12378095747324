import React from 'react' // Importing React

export default function ThirdSection () {
  return (
    <>
      <br />
      <strong className='ml-5'>How We Share Your Information:</strong>
      <br />
      <br />
      <p className='lg:mx-32 ml-5'>
        1. <strong>Third-Party Service Providers:</strong> We may share your information with third-party service providers who assist us in providing and
        improving our services. These providers are obligated to protect your information and use it solely for the purpose of providing services to us.{' '}
        <br /> <br />
        2. <strong>Legal Requirements:</strong> We may disclose your information in response to a legal request, court order, or similar process.
      </p>
    </>
  )
}
